import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import "../styles/index.scss"
import { SEO } from "../components/seo"

const Terms = props => {
  return (
    <Layout pageTitle="Terms of Service">
      <div className="mainContent">
        <div className="termsContainer">
          <h1>REAL ESTATE MEDIA AGREEMENT</h1>
          <p>
            This Agreement, and all the applicable documents referred to in this Agreement (collectively, the“Agreement”), is a legal agreement between Users and Registrants of Media864 (“You” and “Your” and “Users” and “Client”) and Media864 governing the use of the site whether visiting or registering, purchasing products and services (“Media”) or making any other use of this site. These terms and conditions govern our agreement. Your use of the Site and our Prodcuts and Services (“Media”) signifies your acceptance of these terms of service.

            Definitions The Terms “Photographer”, “photography team”, “us”, “we”, and “our” refer to Media864 and all agents, employees, or other representatives. The terms “Media” and “Photography” refer to photography, videos, 3D tours, floorplans, websites etc and all other media services that we create. The terms “agreement” and “contract” refer to this duly executed contractual obligation between parties.

            Property Preparation, Pets, and Requests. Client authorizes us to come onto the Property and capture Media. You have the authority to allow us entry and permit us to capture the Media. You agree to indemnify us, defend us, and pay the expenses of our defense, if a claim is made against us arising out of the services we perform for you. Client will ensure the Property is ready for photographing prior to the scheduled shoot time. Photographer will not be responsible for any staging, cleaning, dusting, or moving of furniture.  Client will ensure any pets are contained during the session, and no pets will be included in the photographs. All specific photographic requests must be received by Photographer at least one (1) calendar day prior to the session.

            Cancellation, Reschedule and Late Arrival.  If the Client fails to show or provide appropriate access and materials, the entire Photographer’s Total Fee is due.  
          </p>
          <p>
              <b>Completion & Delivery.</b> Media864 will provide an online gallery for Client’s review and download accessibility.  Digital materials will be delivered in a high-resolution .jpeg and .mp4 file format.  Processing services include adjustment of white balance, contrast, composition and may employ dodge or shading techniques if appropriate for the photograph. Client should make additional processing requests with sufficient time to allow for normal delays and notify Photographer at the time of the order if there are any extenuating circumstances requiring a quicker turn-around.  The Photographer does not retain archives of the media and all Photographer responsibility for the photographs is completed at delivery. The Photographer will not be held responsible for delivery delays due to the fault of manufacturing and/or delivery services. Client is responsible for the presence of an authorized representative at the session to approve Photographer’s interpretation of the assignment. If Client’s representative is not present, the Photographer’s interpretation will be deemed acceptable.

            Paid Service: Prices/Cancellation. The rate for any Paid Service shall be set forth on the Media864 Media on-line Pricing Page and Order Form. All rates are stated in U.S. dollars and are valid until altered by us. In the event that a Paid Service on this Website is mistakenly listed at an incorrect rate, Media864 reserves the right to refuse or cancel any orders placed for the Paid Service listed at the incorrect rate.

            We agree to provide you with the requested Media. Payment is due at the time of delivery of the Media. If you have paid us in full, you have the Rights to use the Media based on our Commercial License Terms below. 

            We are an independent contractor and not your employee. You are not our agent and cannot make agreements for us.

            When you receive Media from us please check them to be sure they are acceptable. Any additional services requested after delivery of the Media may incur an additional fee. To avoid misunderstandings, you agree to notify us in writing with five (5) business days of receipt of the Media if you believe they are not acceptable.
          </p>
          <p>
          <b>Commercial License Terms.</b>Media864 owns and retains all copyrights for works created or produced by Media864, its affiliates, employees, contractors and subsidiaries. Unless otherwise specifically agreed to in writing by the parties, Media864 hereby grants to Client the non-exclusive, non-sub licensable, revocable, and non-assignable right to reproduce, display, broadcast, and transmit (collectively “use”) the Licensed Content in any and all media, now known or hereafter devised, in the Territory, and during the Term, provided that the Licensed Content may only be used for the sales and marketing of the property depicted in the Licensed Content (the “Subject Property”) while such Subject Property is listed with Client. In the event of non-compliance by Client, the license may be revoked at the discretion of UMedia864. In case of revocation, Client must actively remove any disseminated content.
            In addition, Media864 hereby grants to Client the limited right to sublicense the Licensed Content to one or more multiple listing services (each, an “MLS”) for comparable database use purposes, provided that Client is a member of each such MLS to which a sublicense is granted, and such sublicenses shall not be further sub-licensable.

            Notwithstanding the foregoing, Licensed Content used by Client prior to the expiration of an applicable representation agreement between Client and a third party may continue to be used by Client or the agent representing the subject property after the expiration of such representation agreement, for the sole purpose of marketing the Client or the agent that represented the subject property in the context of the Client or the agent that represented the subject property showing previous properties for sale/sold by Client or the agent that represented the subject property.

            All rights not explicitly granted in this Agreement are reserved to Media864. Media864 is and will remain the sole and exclusive owner of the copyright and all other rights in and to the Licensed Content. No other rights or uses are permitted without the prior written consent of Media864 Media.

            License Restrictions. Except as necessary for the use of the Licensed Content in the end use, Client may not crop, rotate, alter, change or manipulate, or combine the Licensed Content with other image(s) or works without Media864 prior written permission.

            Client may not incorporate the Licensed Content in any logo, trademark, or service mark.
            <br></br>
            When Licensed Content is used on a social media or other third party website or application that permits sharing of content, in the event the website seeks to exploit rights to the Licensed Content contrary to the terms of this Agreement, this Agreement to the extent it extends to the relevant Licensed Content shall be automatically revoked, and Client shall take commercially reasonable efforts to remove the Licensed Content from such website.

            Client is under no circumstance permitted to sell or collect payment of any kind for the use or display of Licensed Content produced by Media864.

            Client is not permitted to assign the rights granted to Client by Media864 to any third parties without express written consent from Media864.

            Copyright Policy and Reporting of Violations. Media864 respects copyright law and expects you to do the same. Unauthorized copying, distribution, modification, public display, or public performance of copyrighted works is an infringement of the copyright holders’ rights.

            As a condition to your use of the Service, or your use of the Site, you agree that you will not use the Site or Service to infringe the intellectual property rights of others in any way. Media864's policy is to terminate access to the Site and use of the Service by any users who are repeat infringers of the copyrights or other intellectual property rights of others.

            Limitations of Liability and Damages. Media864, its affiliates, employees, officers, representatives, service providers and its suppliers shall not be responsible for nor be liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or other damages arising out of or relating in any way to the site, the service, the member creation, the member works, the third party content, or information contained within the site, including, but not limited to, your downloading or uploading of any material or documents or your use of any application or functionality associated with the site or service, even if Media864 has been advised of the possibility of such damages.
          </p>
          <p>
            <b>Indemnification.</b> You agree to indemnify, defend and hold harmless Media864, its officers, directors, employees, agents, licensors, suppliers and any third-party service providers to the Site and/or Service from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, arising from: any breach of these Terms or of any representation, warranty, or covenant you make herein; your access to and use of the Site; your use of the Service; your creation, your use of the Third Party Content; and your grant of rights and licenses to Media864 pursuant to these Terms.

            The language in this Agreement will be interpreted as to its fair meaning and not strictly for or against any party. If any portion of this Agreement is deemed to be illegal or unenforceable, the remaining provisions of this Agreement remain in full force.

            If you have any comments or questions about these Terms, the Site or our Service, please contact us by email at info@media864.com
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Terms" />
)

export default Terms;